import React from 'react'
import { MDXTag } from '@mdx-js/tag'


import DefaultLayout from "/opt/build/repo/src/templates/blog-post.js"

export default class MDXContent extends React.Component {
  constructor(props) {
    super(props)
    this.layout = DefaultLayout
  }
  render() {
    const { components, ...props } = this.props

    return <MDXTag
             name="wrapper"
             Layout={this.layout} layoutProps={props}
             components={components}>

<MDXTag name="p" components={components}>{`After having conducted over a hundred technical interviews
for software engineering hires,
I’ve come to realize that there are many elements
that contribute to making a great interview experience
that are not technical at all.`}</MDXTag>
<MDXTag name="p" components={components}>{`A coding interview at a typical `}<MDXTag name="a" components={components} parentName="p" props={{"href":"https://en.wikipedia.org/wiki/Facebook,_Apple,_Amazon,_Netflix_and_Google"}}>{`FAANG`}</MDXTag>{` involves coming up with an algorithm to solve
a problem (usually on a whiteboard or laptop) in a short amount of
time (around 45 minutes). The candidate will go through
a few of these (and some other non-coding interviews)
before a hiring committee makes a hiring decision based
on feedback from the interviewers. It’s not a perfect process.
Great candidates can certainly have bad days and do poorly.`}</MDXTag>
<MDXTag name="p" components={components}>{`This post isn’t a proposal about how to improve the process, but
some thoughts on how to let the candidate have the best experience
possible in the present setup:`}</MDXTag>
<MDXTag name="h3" components={components}>{`1. Put the candidate at ease first.`}</MDXTag>
<MDXTag name="p" components={components}>{`It can be really stressful to have your career direction
determined over the course of several 45 minute interviews!
It’s not surprising that some candidates are nervous during
the process.`}</MDXTag>
<MDXTag name="p" components={components}>{`The last thing you want to do is dive right into a deep
technical discussion. Ask the candidate how their day has
been going first. Let them know that you’re there to work
together on a problem with them, and it’s not so much about
you asking a question and them providing a response. It’s
also good to let candidates know that a whiteboard interview
is an artificial situation, and that they aren’t expected
to be perfect. Don’t be nitpicky about tiny syntax errors
or the exact order of arguments to a substring call.`}</MDXTag>
<MDXTag name="p" components={components}>{`One of the best interviews that I had when I was interviewing
for a job after college hardly felt like an interview. It
felt like a casual and friendly conversation (while actually
covering technical material quite smoothly). I’ve tried to recreate
that experience for candidates that I’ve interviewed since.`}</MDXTag>
<MDXTag name="h3" components={components}>{`2. Come well prepared.`}</MDXTag>
<MDXTag name="p" components={components}>{`Interviews where you don’t get a sufficient signal about the
candidate’s ability are a waste of everybody’s time.
Come prepared with more questions than you’d expect to ask.
Also, make sure that you’ve thought about every corner case
in your problems and potential directions that candidates
could take.`}</MDXTag>
<MDXTag name="p" components={components}>{`Your job as an interviewer is to steer the candidate to
a working solution without too much hand-holding.
After a few interviews you should have a reasonable idea
about where candidates typically stumble, and you should
be prepared to step in and help them out.`}</MDXTag>
<MDXTag name="p" components={components}>{`Every once in a while you will get a candidate that doesn’t
need any help whatsoever. However, a majority will need some
sort of assistance with the problems you present before
them. The key to a smooth interview process is to never let
the candidate feel stuck or helpless. A well timed hint
can go a long way in helping candidates make progress even
when the problem they are solving is beyond them.`}</MDXTag>
<MDXTag name="p" components={components}>{`I like to pick problems that can be scaled up in difficulty
depending on how much progress the candidate makes. I try
to structure them such that most candidates can solve at least
parts of them, and it gives me the option to work through the
whole thing with more skilled candidates.`}</MDXTag>
<MDXTag name="h3" components={components}>{`3. Focus on the candidate, not your questions.`}</MDXTag>
<MDXTag name="p" components={components}>{`Ask the candidate what their areas of strength are. Do some
research on their past work. Don’t grill candidates about
front-end performance if they’ve only done back-end distributed
systems in the past. Tailor your questions on the fly to something that will tease out the candidate’s problem solving ability more
accurately.  `}</MDXTag>
<MDXTag name="p" components={components}>{`The interview is about the candidate and their ability. This is
a big day for them. Your questions are merely tools to facilitate
a discussion. You may meet candidates that struggle with your
problem initially but demonstrate great perseverance and come
up with a working solution eventually.`}</MDXTag>
<MDXTag name="p" components={components}>{`You’re trying to evaluate if this person is someone that you
would like to work with, not if they aced your test.
The interview should not feel like a test.`}</MDXTag>
<MDXTag name="h3" components={components}>{`4. Pace the interview well.`}</MDXTag>
<MDXTag name="p" components={components}>{`An interview that flows well will feel good for both
the interviewer and the candidate. Time management is paramount.`}</MDXTag>
<MDXTag name="p" components={components}>{`Make sure that you keep track of time in five or ten minute
slots. You want to avoid awkward silences and long periods
where the candidate is just staring blankly at the whiteboard.`}</MDXTag>
<MDXTag name="p" components={components}>{`If the candidate is not making any progress at all and
you need to give up on a particular problem, do so
elegantly without making them feel like they messed up.
Point them in the general direction that you were expecting them
to take and then move on to another problem.`}</MDXTag>
<MDXTag name="p" components={components}>{`Also make sure to budget some time at the end of the interview
to discuss other things and wind down. The last thing you want
is the next interviewer knocking on the door while the
candidate is furiously struggling to get their solution
working.`}</MDXTag>
<MDXTag name="h3" components={components}>{`5. Help candidates take home something positive.`}</MDXTag>
<MDXTag name="p" components={components}>{`I firmly believe that every candidate must get something
out of the interview, even if they don’t get the job. While
you may not be allowed to give the candidate direct feedback
about their performance on the interview, you can give them
several other insights.`}</MDXTag>
<MDXTag name="p" components={components}>{`Talk about your role at the company and how you got there.
Candidates that are not ready to be hired may learn something
that they were missing.`}</MDXTag>
<MDXTag name="p" components={components}>{`Give the candidate some perspective about roles that you think
are a good fit for their interests and skillset. For example,
if the candidate likes machine learning, talk about what typical
machine learning folks do at your company. Even if they don’t
end up getting hired, you may help them narrow down their
search for the next place that they interview at.`}</MDXTag>
<MDXTag name="p" components={components}>{`Overall, remember that this is a real person that you are talking
to, not a resource that could potentially ease the
workload of your team. Thank them for their time and hope
that they find success in their careers (even if that is elsewhere).`}</MDXTag>
<MDXTag name="hr" components={components}></MDXTag>
<MDXTag name="p" components={components}><MDXTag name="em" components={components} parentName="p">{`Views expressed are my own and not my employer’s.`}</MDXTag></MDXTag>
           </MDXTag>
  }
}

export const _frontmatter = {};

  